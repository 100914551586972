.nav-container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 120px;
}
.nav-container .container.active{
  background-color: #fce8bd;
}
.nav-container .container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.nav-lists {
  display: flex;
  justify-content: space-between;
  width: 70%;
  align-items: center;
  margin: 0 auto;
}
.nav-lists a{
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
}
.nav-lists.active a{
  color: #000;
}
.nav-lists .nav-list:hover{
  text-decoration: underline;
}
.nav-lists .nav-logo {
  width: 150px;
}
.nav-lists .nav-logo img {
  width: 100%;
  height: 100%;
}
.checkbox-btn{
    display: none;
}


.nav-languages{
  border: none;
  background: none;
  color: #fff;
  appearance: none;
  font-size: 20px;
  width: 60px;
  text-align: center;
  cursor: pointer;
}
.nav-languages option{
  background-color: #2c3443;
  cursor: pointer;
  color: #fff;
}
.nav-container .container.active .nav-languages{
  color: #000;
}
/* mobile navbar */

.mob-container{
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;
}
.mob-container.active{
  background: #2c3443;
}
.mob-container .mob-nav-logo{
  width: 150px;
}
.mob-container .mob-nav-logo img{
  width: 100%;
  height: 100%;
}
.mob-container .mob-nav-items{
  position: fixed;
  top: 0;
  left: -100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fce8bd;
  width: 40%;
  justify-content: center;
  align-items: center;
}
.mob-container .mob-nav-items.mob-active{
  left: 0;
}
.mob-container .mob-nav-items .close{
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 2rem;
  color: #2c3443;
  cursor: pointer;
}
.mob-container .mob-nav-items .mob-nav-item{
  display:flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}
.mob-container .mob-nav-items .mob-nav-item a{
  margin: 10px 0;
  text-decoration: none;
  font-size: 1.3rem;
  color: #000;
  font-weight: 500;
}
.mob-container .mob-icons{
  padding: 15px;
  background-color: #68718197;
  border-radius: 50%;
  cursor: pointer;
}

.mob-container .mob-icons .mob-icon{
  display: block;
  font-size: 20px;
  color: #fff;

}
.mob-container .mob-nav-items .mob-nav-item .mob-nav-languages{
  border: none;
  background: none;
  color: #000;
  appearance: none;
  font-size: 20px;
  width: 60px;
  text-align: left;
  cursor: pointer;
}

@media screen and (max-width:1200px) {
  .nav-lists {
    width: 80%;
  }
}
@media screen and (max-width:992px) {
  .nav-lists {
    width: 90%;
  }
}
@media screen and (max-width:890px) {
  .nav-lists {
    width: 95%;
  }
  .nav-lists a{
    font-size: 1.1rem;
    margin: 0 5px;
  }
}
@media screen and (max-width:768px) {
  .nav-container .container{
    display: none;
  }
  .mob-container{
    display: flex;
  }
  .mob-container .mob-nav-logo{
    width: 130px;
  }
}

@media screen and (max-width:576px) {
  .mob-container .mob-nav-items{
    width: 60%;
  }
  .mob-container .mob-nav-logo{
    width: 110px;
  }
  .mob-container .mob-icons{
    padding: 10px;
  }
  .mob-container .mob-icons .mob-icon{
    font-size: 15px;
  }
  .mob-container .mob-nav-items .close{
    font-size: 1.5rem;
  }
  .mob-container .mob-nav-items .mob-nav-item a{
    font-size: 1.1rem;
  }
}

@media screen and (max-width:380px) {
  .mob-container .mob-nav-items{
    width: 70%;
  }
  .mob-container .mob-nav-logo{
    width: 100px;
  }
  .mob-container .mob-icons .mob-icon{
    font-size: 20px;
  }
  .mob-container .mob-nav-items .close{
    font-size: 1.3rem;
  }
  .mob-container .mob-nav-items .mob-nav-item a{
    font-size: 1rem;
  }
}

@media screen and (max-width:320px) {
  .mob-container .mob-nav-items{
    width: 80%;
  }
  .mob-container .mob-nav-logo{
    width: 90px;
  }
  .mob-container .mob-icons .mob-icon{
    font-size: 20px;
  }
  .mob-container .mob-nav-items .close{
    font-size: 1.2rem;
  }
  .mob-container .mob-nav-items .mob-nav-item a{
    font-size: 0.9rem;
  }
}
