.yourhome-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px auto 0 auto;
    overflow: hidden;
  }
  
  .section-title {
    font-size: 24px;
    margin-bottom: 30px;
  }
  
  .carousel-container {
    position: relative;
    width: 90%;
    max-width: 800px; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel-card {
    flex: 0 0 auto;
    margin: 0 10px; 
    width: 100%;
  }
  
  .carousel-card .carousel-img {
    width: 100%;
    height: 400px;
    border-radius: 8px; 
  }
  .carousel-indicators {
    position: absolute;
    display: flex;
    gap: 10px;
    width: 500px;
    bottom: 40px;
    left: 40%;
  }
  .carousel-indicators .indicator{
    width: 10px;
    height: 10px;
    top: 0;
    left: -50%;
    border-radius: 50%;
    background-color: #000;
    cursor: pointer;
    z-index: 999;
  }
    .carousel-indicators .indicator.active {
        background-color: #fff;
    }

  .carousel-icon1 {
    position: absolute;
    top: 50%;
    left: -10%;
    transform: translateY(-50%);
    font-size: 2rem;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 8px;
    border-radius: 50%;
    color: #333;
  }
  .carousel-icon2 {
    position: absolute;
    top: 50%;
    right: -10%;
    transform: translateY(-50%);
    font-size: 2rem;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 8px;
    border-radius: 50%;
    color: #333; 
  }
  .carousel-icon:first-child {
    left: 0;
  }
  
  .carousel-icon:last-child {
    right: 0;
  }

  @media screen and (max-width:1200px) {
    .carousel-indicators {
      width: 300px;
      left: 40%;
    }
  }
  
  @media screen and (max-width:992px) {
    .carousel-icon1{
      left: 5%;
    }
    .carousel-icon2{
      right: 5%;
    } 
  }
  @media screen and (max-width:576px) {
    .carousel-card .carousel-img{
      height: 300px;
    }
  }

  @media screen and (max-width:380px) {
    .carousel-card .carousel-img{
      height: 250px;
    } 
    .carousel-indicators {
      width: 200px;
      left: 30%;
    }
    .carousel-indicators .indicator{
      width: 8px;
      height: 8px;
    }
  }

  @media screen and (max-width:320px) {
    .carousel-card .carousel-img{
      height: 200px;
    } 
  }
  