.footer-container{
    width: 100%;
    height: 400px;
    display: flex;
    background-color: #fce8bd;
    margin: 0 auto;
}
.footer-cards{
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}
.footer-card{
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between;
    align-items: flex-start;
}
.footer-card:nth-child(1) .logo img{
    width: 180px;
}
.footer-card:nth-child(1) .footer-text{
    font-size: 1.5rem;
}
.footer-card:nth-child(1) .footer-icons{
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 2rem;
}
.footer-card:nth-child(1) .footer-icons .footer-icon{
    padding: 16px;
    background-color: #000;
    border-radius: 50%;
}
.footer-card:nth-child(1) .footer-icons .footer-icon .icon{
    font-size: 18px;
    color: #fff;
}
.footer-link{
    text-decoration: none;
    color: #555;
    font-size: 25px;
    transition: 1s;
}
.footer-link:hover{
    text-decoration: underline;
}
span{
    font-size: 25px;
}

@media screen and (max-width:1200px) {
    .footer-card:nth-child(1) .logo img{
        width: 150px;
    }
    .footer-card:nth-child(1) .footer-text{
        font-size: 1.3rem;
    }
    .footer-card:nth-child(1) .footer-icons{
        gap: 1.5rem;
    }
    .footer-card:nth-child(1) .footer-icons .footer-icon{
        padding: 12px;
    }
    .footer-card:nth-child(1) .footer-icons .footer-icon .icon{
        font-size: 16px;
    }
    .footer-link{
        font-size: 20px;
    }
    span{
        font-size: 20px;
    }
}
@media screen and (max-width:768px) {
    .footer-card:nth-child(1) .logo img{
        width: 120px;
    }
    .footer-card:nth-child(1) .footer-text{
        font-size: 1.1rem;
    }
    .footer-card:nth-child(1) .footer-icons{
        gap: 1rem;
    }
    .footer-card:nth-child(1) .footer-icons .footer-icon{
        padding: 10px;
    }
    .footer-card:nth-child(1) .footer-icons .footer-icon .icon{
        font-size: 14px;
    }
    .footer-link{
        font-size: 18px;
    }
    span{
        font-size: 18px;
    }
}

@media screen and (max-width:576px) {
    .footer-card:nth-child(1) .logo img{
        width: 100px;
    }
    .footer-card:nth-child(1) .footer-text{
        font-size: 1rem;
    }
    .footer-card:nth-child(1) .footer-icons{
        gap: 0.5rem;
    }
    .footer-card:nth-child(1) .footer-icons .footer-icon{
        padding: 8px;
    }
    .footer-card:nth-child(1) .footer-icons .footer-icon .icon{
        font-size: 12px;
    }
    .footer-link{
        font-size: 16px;
    }
    span{
        font-size: 16px;
    }
}
@media screen and (max-width:460px) {
    .footer-cards{
        gap: 20px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
    }
    .footer-cards .footer-card{
        width: 40%;
    }
}
@media screen and (max-width:380px) {
    .footer-card:nth-child(1) .logo img{
        width: 80px;
    }
    .footer-card:nth-child(1) .footer-text{
        font-size: 0.8rem;
    }
    .footer-card:nth-child(1) .footer-icons{
        gap: 0.3rem;
    }
    .footer-card:nth-child(1) .footer-icons .footer-icon{
        padding: 6px;
    }
    .footer-card:nth-child(1) .footer-icons .footer-icon .icon{
        font-size: 10px;
    }
    .footer-link{
        font-size: 14px;
    }
    span{
        font-size: 14px;
    }
}
@media screen and (max-width:320px) {
    .footer-card:nth-child(1) .logo img{
        width: 70px;
    }
    .footer-card:nth-child(1) .footer-text{
        font-size: 0.7rem;
    }
    .footer-card:nth-child(1) .footer-icons{
        gap: 0.2rem;
    }
    .footer-card:nth-child(1) .footer-icons .footer-icon{
        padding: 4px;
    }
    .footer-card:nth-child(1) .footer-icons .footer-icon .icon{
        font-size: 8px;
    }
    .footer-link{
        font-size: 12px;
    }
    span{
        font-size: 12px;
    }
}