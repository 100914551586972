.news-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
    background: rgb(247, 247, 247);
    width: 100%;
    padding: 50px 0;
}
.news-cards{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 0 auto;
    width: 75%;
}
.news-cards .news-card{
    width: 100%;
    display: flex;
    max-width: 550px;
    justify-content: space-between;
    position: relative;
    max-height: 350px;
    gap: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
}
.news-texts{
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    padding: 10px;
    gap: 1rem;
}
.news-texts .news-btn{
    padding: 8px 25px;
    border-radius: 20px;
    border: none;
    background-color: #2c3442;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: 0.5s ease all;
}
.news-texts .news-btn:hover{
    color: #2c3442;
    border:1px solid #2c3442;
    background-color:#fff;
}
.news-texts .news-title{
    font-size: 1.2rem;
    font-weight: 600;
}
.news-texts .news-descr{
    text-align: justify;
    font-size: 0.9rem;
    color: #2c3442;
}
.news-card .news-image{
    width: 45%;
}
.news-card .news-image img{
    width: 100%;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

@media screen and (max-width:1200px) {
    .news-cards{
        grid-template-columns: 1fr 1fr;
        width: 95%;
    }
    .news-cards .news-card{
        max-height: 300px;
        margin: 0 auto;
    }
    .news-texts{
        width: 100%;
    }
    .news-card .news-image{
        width: 100%;
    }
}
@media screen and (max-width:992px) {
    .news-container{
        padding: 30px 0;
    }
    .news-cards{
        grid-template-columns: 1fr;
        width: 95%;
    }
    .news-cards .news-card{
        max-height: 300px;
        max-width: 100%;
        margin: 0 auto;
    }
    .news-texts{
        width: 100%;
    }
    .news-card .news-image{
        width: 100%;
    }
}
@media screen and (max-width:768px) {
    .news-cards{
        grid-template-columns: 1fr;
        width: 95%;
    }
    .news-cards .news-card{
        max-height: 400px;
        max-width: 100%;
        margin: 0 auto;
        flex-direction: column;
        gap: 0;
        padding-bottom: 20px;
    }
    .news-texts .news-btn{
        padding: 8px 40px;
    }
    .news-texts{
        width: 100%;
        max-height: 50%;
        justify-content: flex-start;
    }
    .news-card .news-image{
        width: 100%;
        max-height: 60%;
    }
    .news-card .news-image img{
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

}
@media screen and (max-width:576px) {
    .news-cards{
        grid-template-columns: 1fr;
        width: 95%;
    }
    .news-cards .news-card{
        max-height: 450px;
        max-width: 100%;
        margin: 0 auto;
        flex-direction: column;
        gap: 0;
    }
    .news-texts .news-btn{
        padding: 8px 35px;
    }
    .news-texts{
        width: 100%;
        padding: 15px;
    }
    .news-card .news-image{
        width: 100%;
        max-height: 50%;
    }
}
@media screen and (max-width:460px) {
    .news-cards{
        grid-template-columns: 1fr;
        width: 95%;
    }
    .news-cards .news-card{
        max-height: 500px;
        max-width: 100%;
        margin: 0 auto;
        flex-direction: column;
        gap: 0;
    }
    .news-texts .news-btn{
        padding: 8px 30px;
    }
    .news-texts{
        width: 100%;
        padding: 15px;
    }
    .news-card .news-image{
        width: 100%;
        max-height: 50%;
    }
}

@media screen and (max-width:380px) {
    .news-container{
        padding: 20px 0;
    }
    .news-cards{
        grid-template-columns: 1fr;
        width: 95%;
    }
    .news-cards .news-card{
        max-height: 530px;
        max-width: 100%;
        margin: 0 auto;
        flex-direction: column;
        gap: 0;
        padding-bottom: 20px;
    }
    .news-texts .news-btn{
        padding: 8px 30px;
    }
}

@media screen and (max-width:320px) {
    .news-cards{
        grid-template-columns: 1fr;
        width: 95%;
    }
    .news-cards .news-card{
        max-height: 560px;
        max-width: 100%;
        margin: 0 auto;
        flex-direction: column;
        gap: 0;
    }
    .news-texts .news-btn{
        padding: 8px 25px;
    }
}