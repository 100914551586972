.partners-container{
    width: 100%;
    margin: 0 auto;
}
.partners-img-container{
    display: grid;
    grid-template-columns: repeat(4,25%);
}
.partners-img-container img{
    object-fit: cover;
    width: 100%;
}
.tex-container{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
}
.tex-img{
    width: 100%;
    max-height: 700px;
    height: 600px;
}
.tex-img img{
    max-height: 700px;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}
.tex-content{
    width: 50%;
    position: absolute;
    left: 10%;
    top: 20%;
}
.tex-content .tex-title{
    text-transform: uppercase;
    font-size: 3rem;
    color: #fff;
}
.tex-content .tex-descr{
    color: #fff;
    line-height: 1.5;
    width: 50%;
    margin-bottom: 30px;
}
.tex-content .tex-btn{
    border: 2px solid #fff;
    color: #fff;
    padding: 7px 20px;
    text-decoration: none;
    font-size: 1.3rem;
    border-radius: 20px;
    transition: 0.5s;
}
.tex-content .tex-btn:hover{
    background-color: #fff;
    color: #2c3442;
}

@media screen and (max-width:1200px) {
    .partners-img-container{
        grid-template-columns: repeat(2,50%);
    }
    .tex-content{
        width: 70%;
        left: 15%;
        top: 30%;
    }
    .tex-content .tex-title{
        font-size: 2.5rem;
    }
    .tex-content .tex-descr{
        width: 70%;
    }
    .tex-content .tex-btn{
        font-size: 1.1rem;
    }
}

@media screen and (max-width:992px) {
    .partners-img-container{
        grid-template-columns: repeat(2,50%);
    }
    .tex-content{
        width: 80%;
        left: 10%;
        top: 30%;
    }
    .tex-content .tex-title{
        font-size: 2rem;
    }
    .tex-content .tex-descr{
        width: 80%;
    }
    .tex-content .tex-btn{
        font-size: 1rem;
    }
}

@media screen and (max-width:768px) {
    .tex-content{
        width: 90%;
        top: 20%;
    }
    .tex-content .tex-title{
        font-size: 1.5rem;
    }
    .tex-content .tex-descr{
        width: 90%;
    }
    .tex-content .tex-btn{
        font-size: 0.9rem;
    }
    .tex-img img{
        max-height: 500px;
    }
}

@media screen and (max-width:576px) {
    .partners-img-container{
        grid-template-columns: repeat(1,100%);
    }
    .tex-content{
        width: 90%;
        top: 20%;
    }
    .tex-content .tex-title{
        font-size: 1.2rem;
    }
    .tex-content .tex-descr{
        width: 90%;
    }
    .tex-content .tex-btn{
        font-size: 0.8rem;
    }
    .tex-img{
        max-height: 500px;
        height: 400px;
    }
    .tex-img img{
        max-height: 400px;
    }
    .partners-img-container img{
        max-height: 400px;
        height: 100%;
    }
}

@media screen and (max-width:380px) {
    .tex-content{
        width: 90%;
        top: 15%;
    }
    .tex-content .tex-title{
        font-size: 1rem;
    }
    .tex-content .tex-descr{
        width: 90%;
        font-size: 0.8rem;
        margin-top: 10px;
    }
    .tex-content .tex-btn{
        font-size: 0.7rem;
    }
    .tex-img{
        max-height: 400px;
        height: 320px;
    }
    .tex-img img{
        max-height: 320px;
    }
    .partners-img-container img{
        max-height: 260px;
        height: 100%;
    }
}

@media screen and (max-width:320px) {
    .tex-content .tex-title{
        font-size: 0.8rem;
    }
    .tex-content .tex-descr{
        width: 90%;
        font-size: 0.7rem;
    }
    .tex-content .tex-btn{
        font-size: 0.6rem;
    }
    .tex-img{
        max-height: 300px;
        height: 270px;
    }
    .tex-img img{
        max-height: 270px;
    }
    .partners-img-container img{
        max-height: 230px;
        height: 100%;
    }
}