.main-container{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: flex-start;
    background-image: url(../../images//main-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    position: relative;
    margin: 0 auto;
}
.main-container .main-content{
    width: 50%;
    position: absolute;
    left: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
}
.main-content h1{
    font-size: 4.5rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 2px;
}
.main-content p{
    font-size: 1.5rem;
    color: #fff;
}
.main-content .main-buttons{
    display: flex;
    gap: 2rem;
}
.main-content .main-buttons a{
    border: 2px solid #fff;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 1.3rem;
    border-radius: 20px;
    transition: 0.5s;
}
.main-content .main-buttons a:hover{
    background-color: #fff;
    color: #555;
}

/* mobile version */
@media screen and (max-width:1200px) {
    .main-content h1{
        font-size: 4rem;
    }
    .main-content p{
        font-size: 1.3rem;
    }
    .main-content .main-buttons a{
        font-size: 1.1rem;
    }
}

@media screen and (max-width:992px) {
    .main-content h1{
        font-size: 3.5rem;
    }
    .main-content p{
        font-size: 1.2rem;
    }
    .main-content .main-buttons a{
        font-size: 1rem;
    }
}

@media screen and (max-width:768px) {
    .main-content h1{
        font-size: 3rem;
    }
    .main-content p{
        font-size: 1.1rem;
    }
    .main-content .main-buttons a{
        font-size: 0.9rem;
    }
}

@media screen and (max-width:576px) {
    .main-content h1{
        font-size: 2.5rem;
    }
    .main-content p{
        font-size: 1rem;
    }
}

@media screen and (max-width:460px) {
    .main-content .main-buttons a{
        max-width: 150px;
        width: 150px;
        text-align: center;
    }
}

@media screen and (max-width:380px) {
    .main-content h1{
        font-size: 2rem;
    }
    .main-content p{
        font-size: 0.9rem;
    }
    .main-content .main-buttons a{
        font-size: 0.9rem;
        width: 130px;
        padding: 10px 0;
        max-width: 130px;
    }
}

@media screen and (max-width:320px) {
    .main-content h1{
        font-size: 1.8rem;
    }
}