.projects-container{
    margin: 0 auto 100px auto;
    width: 100%;
}
.projects-cards{
    width: 75%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    gap: 2rem;
}
.projects-cards .card{
    position: relative;
    width: 100%;
    min-height: 280px;
    overflow: hidden;
}
.card .card-texts{
    position: absolute;
    left: 0;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    transition: bottom 0.3s;
    overflow: hidden;
    background-color: #7674745c;
    height: 100%;
    display: flex;
    flex-direction: column;
    animation: anime 1s ease;
    gap: 1.5rem;
    bottom: -70%;
}

.card:hover .card-texts {
    bottom: 0;
  }
.card:hover .card-texts .card-descr{
    display: inline-block;
}

.card .card-texts .card-title{
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
}
.card .card-texts .card-descr{
    display: none;
    font-size: 1.2rem;
    color: #fff;
    font-weight: 600;
}
.card .card-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}
.card .card-img img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width:1200px) {
    .projects-cards{
        width: 90%;
    }
    .projects-cards .card{
        min-height: 250px;
    }
    .card .card-texts{
        bottom: -70%;
    }
    .card .card-texts .card-title{
        font-size: 1.8rem;
    }
}

@media screen and (max-width:992px) {
    .projects-cards .card{
        min-height: 200px;
        width: 90%;
        margin: 0 auto;
    }
    .card .card-texts .card-title{
        font-size: 1.5rem;
    }
    .card .card-texts .card-descr{
        font-size: 1rem;
    }
}

@media screen and (max-width:768px) {
    .projects-cards{
        grid-template-columns: 1fr;
    }
    .projects-cards .card{
        min-height: 300px;
        width: 100%;
        margin: 0 auto;
    }
}

@media screen and (max-width:576px) {
    .projects-cards .card{
        min-height: 250px;
    }
    .card .card-texts .card-title{
        font-size: 1.4rem;
    }
}

@media screen and (max-width:380px) {
    .projects-container{
        margin-bottom: 50px;
    }
    .projects-cards .card{
        min-height: 200px;
    }
    .card .card-texts .card-title{
        font-size: 1.2rem;
    }
    .card .card-texts .card-descr{
        font-size: 0.9rem;
    }
}

@media screen and (max-width:320px) {
    .projects-cards .card{
        min-height: 150px;
    }
    .card .card-texts .card-title{
        font-size: 1.2rem;
    }
    .card .card-texts .card-descr{
        font-size: 0.8rem;
    }
}
