.services-container {
  display: grid;
  width: 100%;
  margin: 0 auto;
  gap: 20px;
  background-color:#fce8bd;
  gap: 3rem;
}
.services-cards {
  display: grid;
  width: 60%;
  margin: 0 auto;
  gap: 20px;
}

.services-cards .div {
  display: flex;
  gap: 20px;
}
.card1,
.card4 {
  width: 60%;
}
.card2,
.card3 {
  width: 40%;
}
.services-cards .div .card {
  display: flex;
  justify-content: space-between;
  background-color: #2c3442;
  align-items: center;
  padding: 0 20px;
  min-height: 150px;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.5s;
}
.services-cards .div .card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.services-cards .div .card h3 {
  color: #fff;
  letter-spacing: 1.5px;
}
.services-cards .div .card img {
  width: 150px;
  height: 130px;
}

/* company */
.company {
  width: 100%;
  margin: 0 auto;
  display: grid;
  min-height: 300px;
  position: relative;
}
.company .company-img{
  width: 100%;
}
.company .company-img img{
  width: 100%;
  max-height: 600px;
}
.company .company-statistics{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 35%;
  left: 10%;
  gap: 4rem;
}
.company .company-statistics .company-title .title{
  color: #fff;
  font-size: 3.5rem;
  font-weight: 600;
}
.company-statistics .company-cards{
  display: flex;
  gap: 3rem;
}
.company-statistics .company-cards div h1 span, .company-statistics .company-cards div h1{
  color: #fff;
  font-size: 2.5rem;
}
.company-statistics .company-cards div span{
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
}

@media screen and (max-width:1200px) {
  .services-cards {
    width: 80%;
  }
  .services-cards .div .card {
    padding: 0 10px;
  }
  .services-cards .div .card img {
    width: 120px;
    height: 120px;
  }
  .company .company-statistics .company-title .title{
    font-size: 2.5rem;
  }
  .company-statistics .company-cards div h1 span, .company-statistics .company-cards div h1{
    font-size: 2rem;
  }
  .company-statistics .company-cards div span{
    font-size: 1.1rem;
  }
}
@media screen and (max-width:992px) {
  .services-cards {
    width: 90%;
  }
  .services-cards .div .card {
    padding: 0 10px;
  }
  .services-cards .div .card img {
    width: 120px;
    height: 120px;
  }
  .company .company-statistics .company-title .title{
    font-size: 2.3rem;
  }
  .company-statistics .company-cards div h1 span, .company-statistics .company-cards div h1{
    font-size: 2rem;
  }
  .company-statistics .company-cards div span{
    font-size: 1.1rem;
  }
}
@media screen and (max-width:768px) {
  .services-cards {
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }
  .services-cards .div {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin: 0 auto;
  }
  .services-cards .div .card {
    width: 80%;
    min-height: 200px;
  }
  .services-cards .div .card h3 {
    font-size: 1.3rem;
  }
  .services-cards .div .card img {
    width: 150px;
    height: 150px;
  }
  .company .company-statistics .company-title .title{
    font-size: 2rem;
  }
  .company .company-statistics{
    top: 25%;
    left: 25%;
    gap: 20px;
  }
  .company-statistics .company-cards{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-content: center;
    margin: 0 auto;
  }
}
@media screen and (max-width:576px) {
  .services-cards .div .card {
    width: 90%;
    padding: 0 10px;
  }
  .services-cards .div .card h3 {
    font-size: 1.3rem;
  }
  .services-cards .div .card img {
    width: 140px;
    height: 140px;
  }
  .company .company-statistics .company-title .title{
    font-size: 1.5rem;
  }
  .company .company-img img{
    min-height: 600px;
  }
}
@media screen and (max-width:380px) {
  .services-cards .div .card{
    min-height: 150px;
  }
  .services-cards .div .card h3 {
    font-size: 1rem;
  }
  .services-cards .div .card img {
    width: 120px;
    height: 120px;
  }
  .company .company-statistics .company-title .title{
    font-size: 1.5rem;
  }
  .company .company-img img{
    min-height: 500px;
  }
  .company .company-statistics{
    top: 25%;
    left: 15%;
    width: 80%;
  }
  .company .company-statistics .company-cards div .div-text{
    font-size: 0.8rem;
  }
  .company .company-statistics .company-cards div h1{
    font-size: 1.3rem;
  }
  .company .company-statistics .company-cards div h1 span{
    font-size: 1.3rem;
  }
}
@media screen and (max-width:320px) {
  .services-cards .div .card h3 {
    font-size: 1.1rem;
  }
  .services-cards .div .card img {
    width: 100px;
    height: 100px;
  }
  .company .company-statistics .company-title span{
    font-size: 1.3rem;
  }
  .company .company-img img{
    min-height: 400px;
  }
  .company .company-statistics{
    top: 20%;
    left: 10%;
    width: 80%;
  }
  .company .company-statistics .company-cards div .div-text{
    font-size: 0.8rem;
  }
  .company .company-statistics .company-cards div h1{
    font-size: 1rem;
  }
  .company .company-statistics .company-cards div h1 span{
    font-size: 1rem;
  }
}

